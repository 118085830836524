// @ngInject
function acpOverdraftDomainModel(
  $q,
  nsUtil,
  nsProperty,
  acpOverdraftDomainClient,
  acpFeatureModel,
  acpCoreDispatcher
) {
  var model = {};

  /**
   * Property that holds overdraft summary information if exists.
   */
  model.overdraftSummary = nsProperty.create();
  model.optedIn = nsProperty.create();
  model.optedOut = nsProperty.create();

  /**
   * Update user's overdraft state.
   */
  model.update = function() {
    return acpOverdraftDomainClient.summary().then(
      function(response) {
        model.overdraftSummary.set(response);
      },
      function() {
        model.overdraftSummary.set({});
      }
    );
  };

  /**
   * Returns whether the user is currently in grace period.
   * @return {Boolean}
   */
  model.isActiveGracePeriod = function() {
    var summary = model.overdraftSummary.getValue();

    return (
      nsUtil.isDefined(summary) &&
      summary !== null &&
      nsUtil.isDefined(summary.od_grace_start_date_time) &&
      nsUtil.isDefined(summary.od_grace_end_date_time)
    );
  };

  /**
   * Returns whether the user's account is overdraft active.
   * @return {Boolean}
   */
  model.isOverdraftActive = function() {
    var summary = model.overdraftSummary.getValue();

    return (
      nsUtil.isDefined(summary) &&
      summary !== null &&
      nsUtil.isDefined(summary.od_active) &&
      summary.od_active === true
    );
  };

  model.hasOverdraftValue = function() {
    var summary = model.overdraftSummary.getValue();

    return summary !== null;
  };

  function getOverdraftStatus() {
    acpFeatureModel.hasFeature('webapi:overdraftoptout').then(function(data) {
      model.optedIn.set(data);
    });
    acpFeatureModel.hasFeature('webapi:overdraftoptin').then(function(data) {
      model.optedOut.set(data);
    });
  }

  // Used for Google Analytics overdraft enrollment status data layer variable
  model.isOverdraftOptedIn = function() {
    return acpFeatureModel
      .hasFeature('webapi:overdraftoptout')
      .then(function(result) {
        if (result) {
          return 'Yes';
        } else {
          return 'No';
        }
      });
  };

  model.updateOverdraftStatus = function() {
    getOverdraftStatus();
  };

  model.optIn = function() {
    acpCoreDispatcher.overdraft.optIn.attempt.emit();
    return acpOverdraftDomainClient.optIn().then(
      function(data) {
        model.updateOverdraftStatus();
        acpCoreDispatcher.overdraft.optIn.success.emit();
        return data;
      },
      function(err) {
        return $q.reject(err);
      }
    );
  };

  model.optOut = function() {
    acpCoreDispatcher.overdraft.optOut.attempt.emit();
    return acpOverdraftDomainClient.optOut().then(
      function(data) {
        model.updateOverdraftStatus();
        acpCoreDispatcher.overdraft.optOut.success.emit();
        return data;
      },
      function(err) {
        return $q.reject(err);
      }
    );
  };

  return model;
}

export default acpOverdraftDomainModel;
