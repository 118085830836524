import acpOverdraftFeeDetailDisclosureTemplate from './templates/acp-overdraft-fee-detail-disclosure.html';
// @ngInject
function acpOverdraftFeeDetail() {
  /* The data for this directive is coming fron the following API endpoint:
    https://confluence.corp.netspend.com/display/proddev/WebApiV1TransactionObject
    and the actual endpoint is webapi/v1/transactions/debit/pending
*/
  return {
    restrict: 'E',
    template: acpOverdraftFeeDetailDisclosureTemplate,
    scope: {
      overdraftDetails: '='
    }
  };
}

export default acpOverdraftFeeDetail;
