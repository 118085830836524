import acpShowTimezoneFilter from 'shared/angularjs/filters/acp-show-timezone';
import overdraftDomainContent from './overdraft-domain-content.yml';
import acpOverdraftDomainClient from './acp-overdraft-domain-client';
import acpOverdraftDomainModel from './acp-overdraft-domain-model';
import acpOverdraftFeeDetailDirective from './acp-overdraft-fee-detail-directive';
import acpOverdraftDisclosureDirective from './acp-overdraft-disclosure-directive';
import acpOverdraftGracePeriodDirective from './acp-overdraft-grace-period-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
import acpNumberToWordsFilter from 'shared/angularjs/filters/acp-number-to-words';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.overdraft-domain
 *
 * @requires acp.core
 *
 * @description
 * Exposes overdraft related models and directives to display overdraft
 * messaging to the user.
 *
 * @example
 * ```js
 * angular.module('acp.component.overdraft-domain.example', [
 *   require('acp.component.overdraft-domain').name
 * ]);
 * ```
 * ```html
 * <acp-overdraft-grace-period></acp-overdraft-grace-period>
 * <acp-overdraft-disclosure></acp-overdraft-disclosure>
 * ```
 */
var component = ng
  .module('acp.component.overdraft-domain', [
    acpCore.name,
    acpNumberToWordsFilter.name,
    acpShowTimezoneFilter.name
  ])
  .directive('acpOverdraftGracePeriod', acpOverdraftGracePeriodDirective)
  .directive('acpOverdraftDisclosure', acpOverdraftDisclosureDirective)
  .directive('acpOverdraftFeeDetail', acpOverdraftFeeDetailDirective)
  .factory('acpOverdraftDomainModel', acpOverdraftDomainModel)
  .factory('acpOverdraftDomainClient', acpOverdraftDomainClient)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/overdraft-domain',
        overdraftDomainContent
      );
    }
  );

export default component;
export {
  default as overdraftDomainContent
} from './overdraft-domain-content.yml';
