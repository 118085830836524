// @ngInject
function acpOverdraftDomainClient(webapiResource, nsDate, nsUtil) {
  var client = {};

  client.summary = webapiResource({
    path: '/v1/overdraft/summary',
    method: 'GET',
    transformData: function (response) {
      if (response.od_grace_start_date_time) {
        response._od_grace_start_date_time = nsDate.fromApi(
          response.od_grace_start_date_time
        );
      }

      if (response.od_grace_end_date_time) {
        response._od_grace_end_date_time = nsDate.fromApi(
          response.od_grace_end_date_time
        );
      }

      if (response.od_fee_amount) {
        response._od_fee_amount = nsUtil.toDollars(response.od_fee_amount);
      }

      if (response.od_free_limit) {
        // API response is negative cents, convert to positive dollars
        response._od_free_limit = -nsUtil.toDollars(response.od_free_limit);
      }

      if (response.ongoing_eligibility_amount) {
        response._ongoing_eligibility_amount = nsUtil.toDollars(
          response.ongoing_eligibility_amount
        );
      }

      if (response.initial_eligibility_amount) {
        response._initial_eligibility_amount = nsUtil.toDollars(
          response.initial_eligibility_amount
        );
      }

      if (response.dd_lapse_date) {
        response._dd_lapse_date = new Date(
          nsDate.fromApi(response.dd_lapse_date)
        );
      }

      if (response.account_anniversary_date) {
        response._account_anniversary_date = new Date(
          nsDate.fromApi(response.account_anniversary_date)
        );
      }

      if (response.next_account_anniversary_date) {
        response._next_account_anniversary_date = new Date(
          nsDate.fromApi(response.next_account_anniversary_date)
        );
      }

      if (response.cooling_off_end_date) {
        response._cooling_off_end_date = new Date(
          nsDate.fromApi(response.cooling_off_end_date)
        );
      }

      if (response.post_suspension_od_available_date) {
        response._post_suspension_od_available_date = new Date(
          nsDate.fromApi(response.post_suspension_od_available_date)
        );
      }

      // Show 30 days to the user (in disclosures).
      //   The configured backend value includes a buffer to account for weekends and holidays.
      response.ongoing_eligibility_days = 30;

      return response;
    }
  });

  client.optIn = webapiResource({
    path: '/v1/overdraft/opt-in',
    method: 'POST'
  });

  client.optOut = webapiResource({
    path: '/v1/overdraft/opt-out',
    method: 'POST'
  });

  return client;
}

export default acpOverdraftDomainClient;
