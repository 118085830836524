import acpOverdraftGracePeriodTemplate from './templates/acp-overdraft-grace-period.html';
// @ngInject
function acpOverdraftGracePeriodDirective(acpOverdraftDomainModel) {
  return {
    restrict: 'E',
    template: acpOverdraftGracePeriodTemplate,
    scope: {},
    link: function(scope) {
      // Call this so we *always* get a value regardless of when update is called.
      scope.overdraftSummary = acpOverdraftDomainModel.overdraftSummary.getValue();

      scope.$onValue(acpOverdraftDomainModel.overdraftSummary, function(
        response
      ) {
        scope.overdraftSummary = response;
      });

      scope.isActiveGracePeriod = acpOverdraftDomainModel.isActiveGracePeriod;
    }
  };
}

export default acpOverdraftGracePeriodDirective;
