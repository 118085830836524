import acpOverdraftDisclosureTemplate from './templates/acp-overdraft-disclosure.html';
// @ngInject
function acpOverdraftDisclosureDirective(acpOverdraftDomainModel) {
  return {
    restrict: 'E',
    template: acpOverdraftDisclosureTemplate,
    scope: {},
    link: function(scope) {
      // Call this so we *always* get a value regardless of when update is called.
      scope.overdraftSummary = acpOverdraftDomainModel.overdraftSummary.getValue();

      scope.$onValue(acpOverdraftDomainModel.overdraftSummary, function(
        response
      ) {
        scope.overdraftSummary = response;
      });
    }
  };
}

export default acpOverdraftDisclosureDirective;
